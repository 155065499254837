import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {path: '/',name: 'index',component: () => import('@/views/Login.vue')},
    {path: '/login',name: 'login',component: () => import('@/views/Login.vue')},
    {
        path: '/home',name: 'home',component: () => import('@/views/Home.vue'),meta: {title: "管理系统-主页"},
    },
    {
        path: '/detail',name: 'hodetailme',component: () => import('@/views/Detail.vue'),meta: {title: "管理系统-主页"}, 
    },
    { path: '*', name: '404', component: () => import('@/views/404.vue'), meta: { title: 'Not found' } },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router




import Vue from 'vue'
import App from './App.vue'


Vue.config.productionTip = false


// base.css
import '@/assets/fonts/Ding.ttf'
import '@/assets/style/base.css'


// font-awesome
import 'font-awesome/css/font-awesome.min.css'

// 消息通知
Vue.prototype.$EventBus = new Vue();
///  NProgress
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'


// 全局混入
import CommonMixins from '@/mixins/common.js'
import String from "@/utils/String"
import Time from '@/utils/Time'
import Encrypt from '@/encrypt/Encrypt'
Vue.prototype.$string = String;
Vue.prototype.$time = Time;
Vue.prototype.$encrypt = Encrypt;
Vue.mixin(CommonMixins);


// 消息总线
Vue.prototype.$EventBus = new Vue();


// 国际化
import VueI18n from 'vue-i18n'
import {Locale} from '@/i18n/I18n'
Vue.use(VueI18n);
import I18nMessages from '@/i18n/I18nMessages'
const i18n = new VueI18n({
    locale: Locale.ZH_CN.locale, // 定义默认语言为中文 
    messages: I18nMessages
});


/// element ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../theme/index.css'
Vue.use(ElementUI, {
    size: 'small'
});


///  ele image upload
import EleUploadImage from 'vue-ele-upload-image'
Vue.component(EleUploadImage.name, EleUploadImage)


// 全局注册组件 
import ImageUpload from '@/components/upload/ImageUpload.vue'
import Pagination from '@/components/page/Pagination.vue'
import EditDialog from '@/components/page/EditDialog.vue'
Vue.component(ImageUpload.name, ImageUpload);
Vue.component(Pagination.name,Pagination);
Vue.component(EditDialog.name, EditDialog);




import router from './router'
import Storage from '@/storage/Storage.js'
router.beforeEach((to, from, next) => {
    const isLogin = Storage.isLogin();
    if (isLogin) {
        if (to.path == '/login' || to.path == '/') {
            return next('/home');
        }
    } else if (to.path != '/login') {
        return next('/login');
    }

    //判断是否有标题
    if (to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = '管理系统';
    }

    // 加载进度条
    NProgress.start();
    next();
});

router.afterEach(() => {
    NProgress.done();
    NProgress.remove();
});


import store from './store'
const vue = new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')


// webskcet
import Socket from '@/socket/socket'
// import Finnhub from '@/socket/finnhub';
// const finnhub = new Finnhub();


Vue.prototype.$socket = new Socket({
    vue
});



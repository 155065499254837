import Config from '@/config/Config'

function Socket(opt) {

    this.instance = {};
    this.connected = false;
    this.timeout = 0;

    this.init = function(opt) {
        // console.log("Init socket with:");
        // console.log(opt);
        this.vue = opt.vue;
    }

    this._reconnect = function () {
        const self = this;
        self.connected = false;
        if (this.timeout < 60000) this.timeout += 10000;
        setTimeout(()=>{
            self.connect();
        },self.timeout);
    }

    this.connect = function() {
        const self = this;
        self.ws = new WebSocket(Config.socket);
        // 连接成功时
        self.ws.onopen = function() {
            if (self.ws.readyState) {
                self.connected = true;
                self.timeout = 0;
                // 测试发送消息
                // console.log(self.ws);
                self.subscribeMarketDetail();
            }
        }
        // 连接断开时
        self.ws.onclose = function(e) {
            self.connected = false;
            setTimeout(()=>{
                self.connect();
            },30000);
        }
        // 连接超时
        self.ws.onerror = function(e) {
            self.connected = false;
            console.log("connected error , Try reconnect in 2 millis...");
            setTimeout(()=>{
                self.connect();
            },30000)
        }

        // 接收到消息
        self.ws.onmessage = function (e)  {
            // console.log("received Message: ");
            // console.log(e);
            const payload = JSON.parse(e.data);
            self.vue.$EventBus.$emit(payload.channel, payload.content);
            // if (payload.channel == 'CHANNEL_MARKET_DETAIL') {
            //     self.vue.$EventBus.$emit('market_detail', payload.content);
            // } else if (payload.channel == 'CHANNEL_MARKET_KLINE') {
            //     self.vue.$EventBus.$emit('market_kline', payload.content);
            // }
        }
    }

    // 此方法会直接将message写给服务端，请不要直接使用
    this.send = function(message) {
        const self = this;
        if (!self.connected) return;

        if (typeof message == 'function' || typeof message == 'object' || typeof message == 'array') {
            self.ws.send(JSON.stringify(message));
        } else {
            self.ws.send(message);
        }
    }

    this.subscribeMarketDetail = function() {
        this.subscribe('generator');
    }

    this.subscribeMarketKline = function(code) {
        this.subscribe("market_kline_" + code);
    }

    this.desubscribeMarketKline = function(code) {
        this.send({
            command: 'dsubscribe',
            content: {
                topic: 'market_kline_'+code,
            }
        })
    }


    this.subscribe = function(topic) {
        const self = this;
        var body = {
            command: 'subscribe',
            content: {
                topic: topic
            }
        };
        self.send(body);
    }

    

    this.init(opt);
    this.connect();
}

export default Socket;
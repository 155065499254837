import Storage from "@/storage/Storage"
import Request from "@/request/Request"
import Service from "@/request/Service"


const CommonMixin = {

    data: function(){
        return {
            // Enumeration,
            Storage,
            Request,
            Service,
        }
    },
    methods: {
        
        
    }
}


export default CommonMixin
import promise from 'es6-promise';
promise.polyfill();
import axios from 'axios'
import { Notification } from 'element-ui'

import  Storage  from '@/storage/Storage.js'
// import String from '@/utils/String'
import Config from '@/config/Config.js'
// import { times } from 'lodash';
// import  Encrypt  from '@/encrypt/Encrypt.js'


const RespCode = {
    success: 200,
    error: 300,
    failure: 299,
    access_error: 280,
    access_force: 281,
    access_expired: 281,
    access_no_authority: 282,
}


// 请求前缀配置
const axiosConfig = {
    baseURL: Config.baseURL, //ajax请求前缀
    timeout: 60000, // 请求超时时间
}


const responseHanler = (response) => {
    /**
     * code为非10000是抛错
     */
    // const data = response.data;
    // const code = data.code;
    // const message = data.message;

    // if (code == RespCode.access_error || code == RespCode.access_force || code == RespCode.access_expired) {
        
    //     Storage.logout();
    //     setTimeout(function(){
    //         location.href = "/login"
    //     },1000)
    //     return data;
    // } else if (code == RespCode.no_authority) {
    //     Notification.error({
    //         title: '错误',
    //         message: message,
            
    //     })
    //     // 返回上一页
    //     this.$router.go(-1)
    // }
    // else {
    //     return data;
    // }
    return response.data;
}


const errorHandler = (error) => {
    Notification.error({
        title: '错误',
        message: "未知错误",
        
    })
    return Promise.reject(error);
}


const axoisClient = axios.create(axiosConfig);
const axoisJsonClient = axios.create(axiosConfig);
// request拦截器
axoisClient.interceptors.request.use(config => {

    const timestamp = new Date().getTime();
    config.headers["Request-Token"] = Storage.getToken();
    config.headers['Request-Timestamp'] = timestamp;
    
    
    
    if (config.method.toLowerCase() == 'post') {
        config.headers["Content-Type"] = 'application/x-www-form-urlencoded; charset=UTF-8';
    
        config.transformRequest = [function (data = {}) {
            let result = '';
            for (let key in data) {
                result += encodeURIComponent(key) + '=' + encodeURIComponent(data[key]) + '&'
            }
            return result;
        }];
    }
    return config;
}, errorHandler)
axoisClient.interceptors.response.use(responseHanler, errorHandler);

axoisJsonClient.interceptors.request.use(config => {


    config.headers["Content-Type"] = 'application/json; charset=UTF-8';
    const timestamp = new Date().getTime();
    config.headers["Request-Token"] = Storage.getToken();
    config.headers['Request-Timestamp'] = timestamp;

    config.transformRequest = [function (data = {}) {
        return JSON.stringify(data);
    }];
    return config;
}, errorHandler)
axoisJsonClient.interceptors.response.use(responseHanler, errorHandler);


function handleResponse(data, resolve, reject) {
    const code = data.code;
    const message = data.message;

    if (code && message) {
        if (code == RespCode.access_error || code == RespCode.access_force || code == RespCode.access_expired) {
        
            Storage.logout();
            setTimeout(function(){
                location.href = "/login"
            },1000);
            reject(data);
            return;
        } else if (code == RespCode.no_authority) {
            Notification.error({
                title: '错误',
                message: message,
                
            })
            // 返回上一页
            this.$router.go(-1)
            reject(data);
            return;
        } else {
            resolve(data);
        }     
    } else {
        Notification.error({
            message: data.message,
            title: '错误',
        });
        reject(data.code);
    }
}



const Request = {
    post: (url, data) => {
        return new Promise((resolve,reject) => {
            axoisClient.post(url, data).then((response) => {
                handleResponse(response, resolve, reject);
            }).catch((error) => {
                reject(error);
            })
        });
    },
    get: (url, data) => {
        return new Promise((resolve,reject) => {
            axoisClient.get(url, data).then((response) => {
                handleResponse(response, resolve, reject);
            }).catch((error) => {
                reject(error);
            })
        });
    },
    
    jsonPost: (url, data) => {
        return new Promise((resolve,reject) => {
            axoisJsonClient.jsonPost(url, data).then((response) => {
                handleResponse(response, resolve, reject);
            }).catch((error) => {
                reject(error);
            })
        });
    },
    download: (url, data) =>{
        axios({
            url: url,
            method: 'post',
            data: data,
            responseType: 'blob',
            headers: {
                'Access-Token': Storage.getToken(),
            }
        }).then((res) => {
            const content = res.data
            const blob = new Blob([content])//构造一个blob对象来处理数据
            const dispotion = res.headers['content-disposition'];
            const fileName = decodeURIComponent(dispotion.match(/attachment;filename=(\S*)/)[1]);

            //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
            //IE10以上支持blob但是依然不支持download
            if ('download' in document.createElement('a')) { //支持a标签download的浏览器
                const link = document.createElement('a')//创建a标签
                link.download = fileName//a标签添加属性
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                document.body.appendChild(link)
                link.click()//执行下载
                URL.revokeObjectURL(link.href) //释放url
                document.body.removeChild(link)//释放标签
            } else { //其他浏览器
                navigator.msSaveBlob(blob, fileName)
            }
        }).catch(() => {
        });
    },

}


export default Request

const Locale = {
    ZH_CN: {
        locale: 'zh_cn',
        name: '简体中文',
    },
    EN: {
        locale: 'en',
        name: '英文',
    },
    instance() {
        return [this.ZH_CN, this.EN];
    }
}


export {Locale}
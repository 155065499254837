import Request from  '@/request/Request'
import Encrypt from '@/encrypt/Encrypt'




const Service = {
    login: (username, password) => {
        return Request.post("/api/smart/access/admin", {username: username, password: password});
    },
    account: (id) =>{
        return Request.post('/api/smart/account/detail', {key: id});
    },
    authorities: (id) =>{
        return Request.post('/api/smart/account/authorities', {key: id});
    },
    candlestick: (code, period, from, to)=> {
        return Request.post('/api/smart/data/candlestick', {code: code, period: period, from: from, to: to});
    },
}

export default Service